<template>
  <v-checkbox
    v-bind="fieldAttrInput"
    v-model="selected"
    :label="fieldAttributes.name"
    outlined
    dense
    :name="field"
    :disabled="localDisabled"
    :hide-details="hideDetails"
  ></v-checkbox>
</template>
<script>
import { getfieldAttrInput } from "@/js/helper.js";

export default {
  props: {
    fieldAttributes: Object,
    field: String,

    disabled: { type: Boolean, default: false },
    hideDetails: { default: false },
    value: {},
  },
  data: function () {
    return {
      showPassword: false,
    };
  },
  computed: {
    fieldAttrInput() {
      return getfieldAttrInput(this.fieldAttributes);
    },
    localDisabled() {
      return (
        (typeof this.templateContent != "undefined" &&
          typeof this.templateContent.disableAllInputs != "undefined" &&
          this.templateContent.disableAllInputs == true) ||
        this.disabled ||
        (typeof this.fieldAttrInput.disabled != "undefined" &&
          this.fieldAttrInput.disabled == "disabled")
      );
    },
    selected: {
      get: function () {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>